import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from "@angular/core"
import { Assist, IModalListContent, IReportError, IReportErrorWithNull, IReportMistake, Playlist, VideToPlay } from "src/app/interface/interface"
import { FormatTimePipe } from "src/app/pipes/format-time.pipe"
import { sharedModalContent } from "src/app/shared"

@Component({
	selector: "app-goal-assists",
	templateUrl: "./goal-assists.component.html",
	styleUrls: ["./goal-assists.component.scss"],
	providers: [FormatTimePipe],
})
export class GoalAssistsComponent implements OnInit {
	@Output() saveToPlaylist = new EventEmitter<Assist>()
	@Output() playGoalAssist = new EventEmitter<VideToPlay[]>()
	@Output() setSelected = new EventEmitter<VideToPlay[]>()
	@Output() reportMistake = new EventEmitter<IReportMistake>()
	@Input() assists: Assist[] = []
	@Input() matchId: string = ""
	@Input() current_video: number | undefined

	set_video: VideToPlay[] = []
	selected_assists: Assist[] = []

	open_popup: boolean = false
	addClipToPlaylist: boolean = true
	activeGoalAssist: Assist
	reportType: IReportErrorWithNull = null

	showModal: boolean = false
	modalContent = sharedModalContent

	constructor(private cd: ChangeDetectorRef, private timePipe: FormatTimePipe) {}

	ngOnInit(): void {
		this.assists.sort((a, b) => {
			return a.videoTime - b.videoTime
		})
		this.assists.forEach((assist) => {
			assist.gameState = assist.gameState.replace(/:/g, "/")
		})

		this.setSaved()
		this.setSelectedVideo()
		this.cd.detectChanges()
		console.log("MatchID: ", this.matchId)
	}

	selectAssist(assists: Assist): void {
		if (this.selected_assists.length != 0) {
			let vasDeleted = false
			this.selected_assists.forEach((item, index) => {
				if (item.time == assists.time) {
					this.selected_assists.splice(index, 1)
					vasDeleted = true
					return
				}
			})
			if (this.set_video.length != 0) {
				this.set_video.forEach((video) => {
					if (video.videoTime + 5 == assists.videoTime) {
						video.selected = false
						return
					}
				})
			}

			if (!vasDeleted) {
				this.selected_assists.push(assists)
				this.set_video.forEach((video) => {
					if (video.videoTime + 5 == assists.videoTime) {
						video.selected = true
						return
					}
				})
			}
		} else {
			this.selected_assists.push(assists)
			if (this.set_video.length != 0) {
				this.set_video.forEach((video) => {
					if (video.videoTime + 5 == assists.videoTime) {
						video.selected = true
						return
					}
				})
			}
		}
		this.setSelected.emit(this.set_video)
	}

	isActive(assists: Assist): boolean {
		let isIn = false
		this.selected_assists.forEach((item, index) => {
			if (item.time == assists.time) {
				isIn = true
				return
			}
		})
		return isIn
	}

	isVideoActive = (index: number): boolean => this.current_video !== undefined && this.current_video === index

	setSaved() {
		let saved: Playlist[] = JSON.parse(sessionStorage.getItem("playList") || "")
		this.assists.forEach((assist) => {
			assist.saved = false
		})
		if (saved) {
			saved.forEach((item) => {
				if (item.event == "assist") {
					this.assists.forEach((assist) => {
						if (assist.videoTime == item.videoTime && assist.realTime.substring(0, 10) == item.date) {
							assist.saved = true
							return
						}
					})
				}
			})
		}
	}

	setSelectedVideo() {
		this.assists.forEach((assist) => {
			this.set_video.push({
				matchId: this.matchId,
				videoTime: assist.videoTime - 5,
				videoEndTime: assist.videoTime + 5,
				selected: false,
				text: `Asistence ${this.timePipe.transform(assist.time)}`,
			})
		})
		this.setSelected.emit(this.set_video)
	}

	toggleAddToPlaylist() {
		this.addClipToPlaylist = !this.addClipToPlaylist
	}

	setReportType(type: IReportError) {
		this.reportType = type
	}

	addToPlaylist(assist: Assist) {
		if (!assist.saved) {
			assist.saved = true
			this.saveToPlaylist.emit(assist)
		}
	}

	playOne(assist: Assist) {
		let video: VideToPlay[] = [
			{
				matchId: this.matchId,
				videoTime: assist.videoTime - 5,
				videoEndTime: assist.videoTime + 5,
				text: `Asistence ${this.timePipe.transform(assist.time)}`,
			},
		]
		this.playGoalAssist.emit(video)
	}

	toggleModal = (assist?: Assist) => {
		if (assist) {
			this.modalContent.additionalValue = assist
			this.modalContent.sections[0].options[0].checked = assist.saved
			this.activeGoalAssist = assist
		}

		this.modalContent.sections[1].options.forEach((option) => (option.checked = false))
		this.showModal = !this.showModal
	}

	onModalSave = (data: IModalListContent) => {
		if (data.sections[0].options[0].checked && data.additionalValue) {
			this.addToPlaylist(data.additionalValue)
		}

		const checkedOption = data.sections[1].options.find((option) => option.checked)
		if (checkedOption) {
			const reportErrorString = checkedOption.translationKey
			const reportError: IReportError = reportErrorString === "iAmNotInClip" ? "player" : reportErrorString === "wrongEventTime" ? "time" : "other"
			this.reportMistake.emit({
				eventObject: this.activeGoalAssist,
				eventName: "goalAssists",
				error: reportError,
			})
		}

		this.toggleModal()
	}
}
