import { IModalListContent } from "../interface/interface";

export const sharedModalContent: IModalListContent = {
  buttonTranslationKey: "confirm",
  sections: [
    {
      title: "clipSettings",
      options: [
        {
          translationKey: "addClipToPlaylist",
        },
      ],
    },
    {
      title: "reportMistake",
      options: [
        {
          translationKey: "iAmNotInClip",
        },
        {
          translationKey: "wrongEventTime",
        },
        {
          translationKey: "otherMistake",
        },
      ],
    },
  ],
};
