<div class="video-coach-container">
	<div
		class="container widget-item shifts"
		*ngFor="let videoCoachNote of videoCoachNotes; index as i"
		[ngClass]="{ 'active-video': isVideoActive(i) }"
	>
		<div class="flex">
			<div
				class="circle medium-button active-circle"
				[ngClass]="{ 'active-circle': isActive(videoCoachNote) }"
				(click)="selectVideoNote(videoCoachNote)"
			>
				<div class="inner-circle" *ngIf="isActive(videoCoachNote)"></div>
			</div>
			<div class="time gap">
				<div>{{ videoCoachNote.time | formatTime }}</div>
				<div class="video-notes">{{ videoCoachNote.name }}</div>
			</div>
		</div>
		<div class="flex">
			<div class="hover">
				<div class="medium-button" [ngClass]="videoCoachNote.type" (click)="openNotesModal(videoCoachNote)"></div>
			</div>
			<div class="hover" (click)="playOne(videoCoachNote)">
				<div class="medium-button play_default"></div>
			</div>
		</div>
	</div>
</div>

<app-modal *ngIf="showModal" [contentTemplate]="modalContentTemplate" [showCloseButton]="false" (close)="toggleModal()"></app-modal>

<ng-template #modalContentTemplate>
	<app-modal-info-content [content]="modalContent" (close)="toggleModal()"></app-modal-info-content>
</ng-template>
