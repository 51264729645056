import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from "@angular/core"
import { IModalInfoContent, VideoClip, VideToPlay } from "src/app/interface/interface"
import { FormatTimePipe } from "src/app/pipes/format-time.pipe"
import { TranslatePipe } from "src/app/pipes/translate.pipe"

@Component({
	selector: "app-videocoach-notes",
	templateUrl: "./videocoach-notes.component.html",
	styleUrls: ["./videocoach-notes.component.scss"],
	providers: [FormatTimePipe, TranslatePipe],
})
export class VideoCoachNotesComponent implements OnInit {
	@Output() playVideo = new EventEmitter<VideToPlay[]>()
	@Output() setSelected = new EventEmitter<VideToPlay[]>()
	@Input() matchId: string = ""
	@Input() videoCoachNotes: VideoClip[] = []
	@Input() current_video: number | undefined

	set_video: VideToPlay[] = []
	selected_videoCoachNotes: VideoClip[] = []
	showModal: boolean = false
	modalContent: IModalInfoContent

	constructor(private cd: ChangeDetectorRef, private timePipe: FormatTimePipe, private translate: TranslatePipe) {}

	ngOnInit(): void {
		this.videoCoachNotes.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())

		this.setSelectedVideo()
		this.cd.detectChanges()
	}

	selectVideoNote(videoCoachNotes: VideoClip): void {
		if (this.selected_videoCoachNotes.length != 0) {
			let wasDeleted = false
			this.selected_videoCoachNotes.forEach((item, index) => {
				if (item.id == videoCoachNotes.id) {
					this.selected_videoCoachNotes.splice(index, 1)
					wasDeleted = true
					return
				}
			})

			if (this.set_video.length != 0) {
				this.set_video.forEach((video) => {
					if (video.id == videoCoachNotes.id) {
						video.selected = false
						return
					}
				})
			}

			if (!wasDeleted) {
				this.selected_videoCoachNotes.push(videoCoachNotes)
				this.set_video.forEach((video) => {
					if (video.id == videoCoachNotes.id) {
						video.selected = true
						return
					}
				})
			}
		} else {
			this.selected_videoCoachNotes.push(videoCoachNotes)
			if (this.set_video.length != 0) {
				this.set_video.forEach((video) => {
					if (video.id == videoCoachNotes.id) {
						video.selected = true
						return
					}
				})
			}
		}
		this.setSelected.emit(this.set_video)
		console.log("Active video", this.set_video)
	}

	isActive(videoCoachNotes: VideoClip): boolean {
		let isIn = false
		this.selected_videoCoachNotes.forEach((item, index) => {
			if (item.id == videoCoachNotes.id) {
				isIn = true
				return
			}
		})
		return isIn
	}

	isVideoActive = (index: number): boolean => this.current_video !== undefined && this.current_video === index

	setSelectedVideo() {
		this.videoCoachNotes.forEach((notes) => {
			let video = {
				matchId: this.matchId,
				videoTime: notes.videoTime,
				videoEndTime: notes.endVideoTime,
				videoId: notes.videoId,
				selected: false,
				id: notes.id,
				text: `${notes.name ? notes.name : this.translate.transform(notes.event)} ${this.timePipe.transform(notes.time)}`,
			}
			console.log(video)
			this.set_video.push(video)
		})
		console.log("set Video", this.set_video)
		this.setSelected.emit(this.set_video)
	}

	playOne(videoCoachNote: VideoClip) {
		let video: VideToPlay[] = [
			{
				matchId: this.matchId,
				videoTime: videoCoachNote.videoTime,
				videoEndTime: videoCoachNote.endVideoTime,
				videoId: videoCoachNote.videoId,
				text: `${videoCoachNote.name ? videoCoachNote.name : this.translate.transform(videoCoachNote.event)} ${this.timePipe.transform(
					videoCoachNote.time
				)}`,
			},
		]
		this.playVideo.emit(video)
	}

	toggleModal() {
		this.showModal = !this.showModal
	}

	openNotesModal(item: VideoClip) {
		console.log("item", item)
		console.log("Videocoach notes", item, this.videoCoachNotes)
		this.modalContent = item

		this.toggleModal()
	}
}
